import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useBranch } from '../../contex/BranchContex.js';
import ConfirmModal from '../molecules/ConfirmModal.js';
import DivisionModal from '../molecules/DivisionModal.js'; // Import the DivisionModal component
import Navigation from '../molecules/Navigation.js';

const DivisionManagementPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const branch = useBranch();

  const openConfirmModal = (id) => {
    setItemToDelete(id);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setItemToDelete(null);
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const confirmDelete = () => {
    handleDelete(itemToDelete);
    closeConfirmModal();
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${apiUrl}/role/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchData.id,
        },
      });

      if (response.ok) {
        fetchData();
      } else {
        console.error('Failed to delete division');
      }
    } catch (error) {
      console.error('Error deleting division:', error);
    }
  };

  const openAddDivisionModal = () => {
    setIsModalOpen(true);
    setSelectedDivision(null); // Clear selected division for new division
  };

  const openEditDivisionModal = (division) => {
    setIsModalOpen(true);
    setSelectedDivision(division); // Set the selected division for editing
  };

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    setLoading(true);
    console.log('ini dari divis management',branch)
    try {
      const response = await fetch(`${apiUrl}/role?search=${search}&order=${order}&pagination=true&page=${page}`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchData.id,
        },
      });

      const res = await response.json();
      setData(res.data.item);
      console.log(res.data.item)
      setTotalPages(Math.ceil(res.data.total / pageSize));
      setTotalItems(res.data.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, search, order]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSort = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const handleResetFilters = () => {
    setSearch('');
    setOrder('asc');
  };

  const handleDivisionSuccess = () => {
    fetchData(); // Refresh the division list after adding or editing a division
    setIsModalOpen(false); // Close the modal
    setSelectedDivision(null); // Reset selected division
    setDropdownOpen(null)
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-start items-center">
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={confirmDelete}
        message="Apakah Anda yakin ingin menghapus divisi ini?"
      />
      <DivisionModal 
        isModalOpen={isModalOpen} 
        closeModal={() => setIsModalOpen(false)} 
        handleDivisionSuccess={handleDivisionSuccess} 
        divisionData={selectedDivision} 
      />
      <Navigation />
      <div className="flex-grow flex flex-col gap-6 w-full max-w-7xl px-[20%]">
        <div className="flex flex-col items-start w-full gap-2 sm:gap-4">
          <h1 className="text-2xl sm:text-4xl font-semibold text-gray-800">Manajemen Divisi</h1>
          <p className="text-sm sm:text-base text-gray-600">Kelola divisi dan hak akses mereka di sini.</p>
        </div>

        {/* Filter and Add Section */}
        <div className="flex flex-col sm:flex-row justify-between w-full">
          <div className="flex items-center w-full sm:w-80 h-12 bg-white border border-gray-300 rounded-full px-4">
            <input
              value={search}
              onChange={handleSearch}
              className="w-full bg-transparent text-gray-700 placeholder-gray-500 text-sm focus:outline-none"
              placeholder="Cari divisi"
            />
          </div>
          <button onClick={openAddDivisionModal} className="px-4 py-2 bg-blue-500 text-white rounded-full text-sm flex items-center mt-2 sm:mt-0">
            Tambah Divisi
          </button>
        </div>

        <div className="hidden sm:flex w-full bg-gray-100 rounded-lg items-center p-3 text-gray-800">
          <div className="w-12 text-center font-medium">No</div>
          <div className="flex-1 font-medium">code Divisi</div>
          <div className="flex-1 font-medium">Nama Divisi</div>
          <div className="w-24 text-center font-medium">Aksi</div>
        </div>
        <div className="w-full bg-white rounded-lg shadow">
          {loading ? (
            <div className="p-4 text-center">Loading...</div>
          ) : (
            Array.isArray(data) && data.length > 0 ? (
              data.map((division, index) => (
                <div key={division.id} className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-100 p-3">
                  <div className="w-12 text-center text-gray-700">{index + 1}</div>
                  <div className="flex-1 text-gray-700">{division.code}</div>
                  <div className="flex-1 text-gray-700">{division.name}</div>
                  <div className="relative w-24 flex justify-center">
                    <button onClick={() => toggleDropdown(division.id)} className="text-gray-600 hover:text-gray-800 px-3 py-1 rounded focus:outline-none">
                      ⋮
                    </button>
                    {dropdownOpen === division.id && (
                      <div className="absolute top-full right-0 mt-2 w-36 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
                        <button
                          onClick={() => openEditDivisionModal(division)} 
                          className="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => openConfirmModal(division.id)}
                          className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-500">No divisions found.</div>
            )
          )}
        </div>
        <div className="w-full flex justify-between items-center py-4 text-sm">
          <div className="text-gray-600">{`Menampilkan ${data.length} dari ${totalItems} data`}</div>
          <div className="flex gap-2">
            {[...Array(totalPages)].map((_, idx) => (
              <button
                key={idx}
                onClick={() => handlePageChange(idx + 1)}
                className={`px-3 py-1 rounded-full ${page === idx + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'}`}
              >
                {idx + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DivisionManagementPage;
