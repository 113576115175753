import { useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../atoms/Header";
import { login } from '../../api/loginApi';
import { useNavigate } from 'react-router-dom';
import { useBranch } from '../../contex/BranchContex.js';

const LoginForm = ({ onSubmit, branchCode }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); 
  const branch = useBranch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Panggil API login
    const result = await login(username, password, branch);

    if (result) {
      navigate(`/${branch.branchCode}/reset`);
      // Lakukan tindakan setelah login berhasil, seperti redirect
    } else {
      setErrorMessage('Username or password is incorrect.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 px-4">
      {/* Header Section */}
      <div className="flex flex-col items-center space-y-2">
        <div className="text-center">
          <Header text="Masuk ke" color="black" />
          <Header text="TanyaAja" color="#0693ff" />
        </div>
        <div className="text-[#393b40] text-[15px] font-normal font-['Poppins']">
          Akses percakapan pintar menggunakan akun anda.
        </div>
      </div>
  
      {/* Input Fields Section */}
      <div className="space-y-5">
        {/* Username Field */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <div className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">Username</div>
          </div>
          <input
            type="text"
            placeholder="Masukan Username anda"
            className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
  
        {/* Password Field */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <div className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">Password</div>
            {/* <Link to={`/${branchCode.branchCode.code}/forgot`} className="text-[#0693ff] text-[15px] font-medium font-['Poppins'] capitalize">Lupa Password</Link> */}
          </div>
          <input
            type="password"
            placeholder="Masukan password"
            className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
  
      {/* Submit Button */}
      <div className="flex justify-center">
        <button type="submit" className="w-full h-12 bg-[#0693ff] text-[#edfaff] rounded flex items-center justify-center text-[15px] font-medium font-['Poppins'] capitalize">
          Masuk
        </button>
      </div>
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
  
      {/* Signup Link */}
      {/* <div className="flex justify-center items-center space-x-1">
        <div className="text-[#6a717c] text-[15px] font-normal font-['Poppins']">Belum punya akun?</div>
        <Link
          // to={`/${branchCode.branchCode.code}/register`}
          className="text-[#0693ff] text-[15px] font-medium font-['Poppins'] capitalize"
        >Daftar sekarang</Link>
      </div> */}
    </form>
  );
};

export default LoginForm;
