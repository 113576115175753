import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import LoginPage from "./components/organisms/LoginPage";
import RegisterPage from "./components/organisms/RegisterPage";
import ForgotPage from "./components/organisms/ForgotPage";
import ResetPage from "./components/organisms/ChatAIPage";
import SettingPage from "./components/organisms/Setting";
import ChatPage from "./components/organisms/ChatAIPageLogin";
import UserPage from "./components/organisms/UserManagement";
import DivisionPage from "./components/organisms/DivisiManagement";
import NotFoundPage from "./components/organisms/NotFoundPage";  // Halaman not found
import { BranchProvider, useBranch } from './contex/BranchContex';

const App = () => {
  return (
    <BranchProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/:branchCode/*" element={<MainRoutes />} />
            <Route path="/notfound" element={<NotFoundPage />} /> {/* Route untuk halaman NotFound */}
          </Routes>
        </div>
      </Router>
    </BranchProvider>
  );
};

const MainRoutes = () => {
  const { branchCode } = useParams(); // Ambil branchCode dari URL
  const { setBranchCode, branchData, loading } = useBranch(); // Gunakan setBranchCode dari context

  useEffect(() => {
    if (branchCode) {
      setBranchCode(branchCode);
      console.debug("branchCode:", branchCode);
    }
  }, [branchCode, setBranchCode]);

  // Tampilkan loading jika masih dalam proses pengambilan data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Jika branchCode tidak ada, redirect ke branchCode default
  if (branchData == null) {
    return <Navigate to="/notfound" replace />;
  }

  return (
    <Routes>
      <Route path={`login`} element={<LoginPage />} />
      <Route path={`register`} element={<RegisterPage />} />
      <Route path={`setting`} element={<SettingPage />} />
      <Route path={`forgot`} element={<ForgotPage />} />
      <Route path={`reset`} element={<ResetPage />} />
      <Route path={`chat`} element={<ChatPage />} />
      <Route path={`user`} element={<UserPage />} />
      <Route path={`division`} element={<DivisionPage />} />
      {/* <Route path="*" element={<Navigate to={`login`} replace />} /> */}
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  );
};

export default App;
