import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  
import LoginForm from "../molecules/LoginForm";
import Logo from "./Logo.png";
import Background from "./Bg.png";
import Texture from "./texture.svg";
import { useBranch } from '../../contex/BranchContex.js';

const LoginPage = () => {
  const branch = useBranch();
  const navigate = useNavigate();  

  useEffect(() => {
    // Cek apakah token ada di localStorage
    const token = localStorage.getItem('accessToken');
    if (token) {
      // Jika token ada, redirect ke halaman reset
     
      navigate(`/${branch.branchCode}/reset`);
    }
    
  }, [navigate, branch]); 

    if (!branch) {
      return <div>Loading...</div>;
    }
  

  const handleLogin = (data) => {
    // Implementasikan login dengan socket.io atau API
  };

  return (
    <div
      className="w-full h-screen bg-cover bg-center flex"
    >
      <img
         src="https://i.imgur.com/2Exr44m.png"
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
      />
      <div className="flex w-full h-full">
        {/* Left Section - Logo */}
        <div className="flex-1 hidden md:flex justify-center items-center bg-black bg-opacity-5">
          <img
            src={Logo}
            width={240}
            alt="Logo"
            className="h-32 object-contain"
          />
        </div>

        {/* Right Section - Login Form */}
        <div className="flex-1 flex justify-center items-center bg-white bg-opacity-80">
          <div className="w-full max-w-md">
            <LoginForm onSubmit={handleLogin} branchCode={branch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
