import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBranch } from '../../contex/BranchContex.js';
import axios from 'axios';

const Navigation = () => {
  const branch = useBranch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [roleName, setRoleName] = useState('');

  // ID pengguna, ambil dari localStorage atau state
  const userId = localStorage.getItem('userId'); // Pastikan userId tersimpan di localStorage
  const apiKey = process.env.REACT_APP_API_KEY;
  const branchCode = branch.branchData.code;
  const token = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/${user.data.user_id}`, {
          headers: {
            'x-api-key': apiKey,
            'x-branch': branchCode,
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = response.data.data;
        setUserName(data.fullname); // Asumsikan 'fullname' adalah field yang berisi nama pengguna
        setRoleName(data.role_name); // Asumsikan 'role_name' adalah field yang berisi nama role pengguna
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId, apiKey, branchCode, token]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    navigate(`/${branchCode}/login`);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="w-full h-[84px] px-6 py-6 md:px-12 bg-white flex justify-between items-center">
      {/* Left section: Logo and Menu */}
      <div className="flex items-center gap-4 md:gap-10">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 bg-[#0693ff] rounded-md flex justify-center items-center">
            <div className="relative w-6 h-5">
              <div className="absolute w-[7.76px] h-[8.47px] left-[8.47px] top-[11.51px] bg-white rounded-tl-sm rounded-bl-[0.71px] rounded-br-md" />
              <div className="absolute w-6 h-[9.88px] p-[2.82px] bg-white rounded-[69.88px] flex gap-[2.82px]">
                <div className="w-[4.24px] h-[4.24px] bg-[#0693ff] rounded-full" />
                <div className="w-[4.24px] h-[4.24px] bg-[#0693ff] rounded-full" />
                <div className="w-[4.24px] h-[4.24px] bg-[#0693ff] rounded-full" />
              </div>
            </div>
          </div>
          <div>
            <span className="text-[#0693ff] text-lg md:text-xl font-bold font-['Poppins']">Tanya</span>
            <span className="text-[#0693ff] text-lg md:text-xl font-bold font-['Montserrat']">Aja</span>
          </div>
        </div>
        {/* Menu Items */}
        <div className="hidden md:flex gap-6">
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 p-px flex justify-center items-center">
              <div className="w-[17.92px] h-[17.92px] relative" />
            </div>
            <Link to={`/${branchCode}/reset`} className="text-[#393b40] text-[15px] font-medium font-['Poppins'] capitalize">Chat</Link>
          </div>
          <div className="relative flex items-center gap-2">
            <div className="w-5 h-5 flex justify-center items-center">
              <div className="w-[15.71px] h-[16.67px] relative" />
            </div>
            <button onClick={toggleDropdown} className="text-[#393b40] text-[15px] font-medium font-['Poppins'] capitalize">Pengaturan</button>
            {dropdownOpen && (
              <div className="absolute top-full mt-2 right-0 w-40 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
                <Link to={`/${branchCode}/setting`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Dataset</Link>
                <Link to={`/${branchCode}/division`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Divisi</Link>
                <Link to={`/${branchCode}/user`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">User</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Right section: User Info */}
      <div className="relative flex items-center gap-2">
        <div className="flex flex-col items-end gap-1">
          <div className="text-[#393b40] text-xs md:text-sm font-semibold font-['Poppins'] capitalize">Halo, {userName}</div>
          <div className="h-5 px-3 bg-[#edfaff] rounded-full flex justify-center items-center">
            <div className="text-[#0693ff] text-[10px] md:text-[12px] font-medium font-['Poppins']">{roleName}</div>
          </div>
        </div>
        <img onClick={toggleProfileDropdown} className="w-8 h-8 md:w-10 md:h-10 rounded-full cursor-pointer" src="https://via.placeholder.com/40x40" alt="User profile" />
        {profileDropdownOpen && (
          <div className="absolute top-full mt-2 right-0 w-40 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
          </div>
        )}
      </div>
      {/* Burger Menu for smaller screens */}
      <div className="md:hidden flex items-center relative">
        <button onClick={toggleMobileMenu}>
          <svg
            className="w-6 h-6 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        {mobileMenuOpen && (
          <div className="absolute top-full right-0 mt-2 min-w-[180px] bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
            <Link to={`/${branchCode}/reset`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Chat</Link>
            <Link to={`/${branchCode}/setting`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Dataset</Link>
            <Link to={`/${branchCode}/division`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Divisi</Link>
            <Link to={`/${branchCode}/user`} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">User</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
