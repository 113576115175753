// src/api/loginApi.js
const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const login = async (username, password, branch) => {
    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':apiKey,
          'x-branch': branch.branchData.id
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      });
  
      if (!response.ok) {
        throw new Error('Login failed');
      }
  
      const data = await response.json();
  
      // Simpan token akses dan refresh ke localStorage
      localStorage.setItem('accessToken', data.data.access);
      localStorage.setItem('refreshToken', data.data.refresh);
  
      // Ambil informasi user dari token
      const userInfo = parseJwt(data.data.access);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
  

      return data;
    } catch (error) {
      console.error("Login error:", error);
    }
  };
  
  // Fungsi untuk parsing JWT
  export const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Error parsing JWT:", error);
      return null;
    }
  };
  