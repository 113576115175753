import { Link } from 'react-router-dom';

const ForgotPage = () => {

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
  <div className="flex flex-col justify-center items-center gap-10">
    {/* Logo Section */}
    <div className="flex items-center gap-2">
      <div className="w-10 h-10 bg-[#0693ff] rounded-md flex justify-center items-center">
        <div className="relative w-[30px] h-[24.98px]">
          <div className="absolute left-[10.59px] top-[14.39px] w-[9.71px] h-[10.59px] bg-white rounded-tl-sm rounded-bl-[0.88px] rounded-br-[7.06px]" />
          <div className="absolute top-0 w-[30px] h-[12.35px] flex items-center justify-start gap-[3.53px] bg-white p-[3.53px] rounded-[87.35px]">
            <div className="w-[5.29px] h-[5.29px] bg-[#0693ff] rounded-full" />
            <div className="w-[5.29px] h-[5.29px] bg-[#0693ff] rounded-full" />
            <div className="w-[5.29px] h-[5.29px] bg-[#0693ff] rounded-full" />
          </div>
        </div>
      </div>
      <div>
        <span className="text-[#0693ff] text-[25px] font-bold font-['Poppins']">Tanya</span>
        <span className="text-[#0693ff] text-[25px] font-bold font-['Montserrat']">Aja</span>
      </div>
    </div>

    {/* Title and Description */}
    <div className="flex flex-col justify-start items-center text-center">
      <div className="text-[#393b40] text-[46px] font-medium font-['Poppins']">Lupa Password?</div>
      <div className="w-[432px] text-[#393b40] text-[15px] font-normal font-['Poppins']">
        Masukan email yang sudah terdaftar, link reset password akan dikirim melalui email.
      </div>
    </div>

    {/* Input Section */}
    <div className="flex flex-col gap-5 items-center">
      <div className="flex flex-col justify-start gap-2 w-[432px]">
        <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">Email</label>
        <input
          type="email"
          placeholder="Masukan email anda"
          className="w-full h-12 px-4 bg-white border border-[#cdd0d4] rounded text-[#6a717c] text-[15px] font-normal font-['Poppins']"
        />
      </div>
      
      {/* Submit Button */}
      <button className="w-[432px] h-12 bg-[#0693ff] rounded flex justify-center items-center text-[#edfaff] text-[15px] font-medium font-['Poppins'] capitalize">
        Kirim Link
      </button>
    </div>

    {/* Back to Login */}
    <div className="flex justify-center items-center gap-2">
    <Link
      to="/login" className="text-[#007dfc] text-xs font-medium font-['Poppins'] capitalize cursor-pointer">
        Kembali ke halaman login
      </Link>
    </div>
  </div>
</div>

  );
};

export default ForgotPage;
