import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useBranch } from '../../contex/BranchContex.js';
import Select from 'react-select'; // Import react-select

const UploadModal = ({ isModalOpen, closeModal, handleUploadSuccess }) => {
  const [files, setFiles] = useState([]); // State untuk menyimpan file yang dipilih
  const [division, setDivision] = useState(null); // State untuk divisi
  const [datasetName, setDatasetName] = useState(''); // State untuk nama dataset
  const [isUploading, setIsUploading] = useState(false); // State untuk loading
  const [divisions, setDivisions] = useState([]); // State untuk daftar divisi
  const branch = useBranch();
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;

  // Dropzone untuk file upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': [],
      'text/csv': [],
      'application/json': [],
      'text/plain': []
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    maxSize: 80000000 // Limit file size to 80 MB
  });

  // Fungsi untuk mengambil daftar divisi dari API
  const fetchDivisions = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${apiUrl}/role`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchData.id
        },
      });

      if (response.ok) {
        const result = await response.json();
        // Assuming the API returns an array of roles with id and name
        const options = result.data.map(role => ({
          value: role.id,
          label: role.name
        }));
        setDivisions(options);
      } else {
        console.error('Error fetching divisions:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  // UseEffect untuk mengambil data divisi saat modal terbuka
  useEffect(() => {
    if (isModalOpen) {
      fetchDivisions();
    }
  }, [isModalOpen]);

  // Fungsi untuk menghandle upload file ke API
// Fungsi untuk menghandle upload file ke API
const handleUpload = async () => {
  if (!files.length || !datasetName || !division) {
    alert('Nama dataset, file, dan divisi harus diisi');
    return;
  }

  const token = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('collection', datasetName); // Nama collection dari input
  formData.append('file', files[0]); // File yang dipilih
  formData.append('role_id', division.value); // role_id berdasarkan pilihan divisi

  try {
    setIsUploading(true); // Set state loading
    const response = await fetch('https://api.tanyaaja.id/api/v1/dataset/upload', {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
        'Authorization': `Bearer ${token}`
      },
      body: formData // Form data yang berisi file dan collection
    });

    const result = await response.json();
    if (response.ok) {
      alert('File berhasil diupload!');
      handleUploadSuccess();
      closeModal(); // Tutup modal setelah upload berhasil
    } else {
      // Show error message from server response, including "errors" field if present
      const errorMessage = result.errors || result.message || 'Gagal upload file';
      alert(`Gagal upload file: ${errorMessage}`);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    alert('Terjadi kesalahan saat mengupload file');
  } finally {
    setIsUploading(false); // Set state loading selesai
  }
};


  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };


  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-[560px] max-h-[80vh] bg-white rounded-xl flex-col inline-flex">
            <div className="self-stretch px-4 py-3 border-b border-[#cdd0d4] flex items-center gap-3">
              <div className="text-[#393b40] text-[19px] font-semibold capitalize">Upload Datasheet</div>
            </div>

            {/* Scrollable body */}
            <div className="self-stretch overflow-y-auto px-4 py-3 flex-col flex" style={{ maxHeight: 'calc(80vh - 72px)' }}>
              {/* <div className="self-stretch h-12 rounded justify-start items-start inline-flex">
                <div className="grow shrink basis-0 h-12 px-4 bg-[#0693ff] justify-center items-center gap-3 flex">
                  <div className="text-[#edfaff] text-[15px] font-medium capitalize">Upload File</div>
                </div>
                <div className="grow shrink basis-0 h-12 px-4 bg-[#f5f6f6] justify-center items-center gap-3 flex">
                  <div className="text-[#6a717c] text-[15px] font-medium capitalize">Upload Link</div>
                </div>
              </div> */}

              <div {...getRootProps()} className="self-stretch h-[88px] p-5 bg-[#f5f6f6] rounded-lg border border-[#cdd0d4] justify-center items-center gap-4 inline-flex">
                <input {...getInputProps()} />
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-[#808790] text-[15px] font-medium">Klik atau tarik file ke sini</div>
                  <div className="justify-center items-center gap-2 inline-flex">
                    <div className="text-[#808790] text-xs">Limit 80mb</div>
                    <div className="w-0.5 h-0.5 bg-[#808790] rounded-full" />
                    <div className="text-[#808790] text-xs">CSV, PDF, TXT, JSON</div>
                  </div>
                </div>
              </div>

              {files.length > 0 && (
                <div className="mt-4">
                  <div className="text-[#393b40] text-xs font-medium">File yang dipilih:</div>
                  <div className="grid grid-cols-1 gap-4 mt-2">
                    {files.map((file) => (
                      <div key={file.name} className="flex items-center bg-[#f9f9f9] p-3 rounded-lg shadow hover:shadow-lg transition-shadow">
                        <div className="w-8 h-8 bg-[#e1e1e1] rounded-full flex justify-center items-center mr-3">
                          <svg className="w-4 h-4 text-[#6a717c]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                          </svg>
                        </div>
                        <div className="flex-grow text-[#6a717c] text-sm">{file.name}</div>
                        <button
                          onClick={() => handleRemoveFile(file.name)}
                          className="text-red-500 text-xs ml-3"
                        >
                          Hapus
                        </button>
                      </div>
                    ))}
                  </div>
                  </div>
              )}


              {/* Nama Datasheet Input */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Nama Datasheet</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4] justify-start items-center gap-2 inline-flex">
                  <input
                    type="text"
                    value={datasetName}
                    onChange={(e) => setDatasetName(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Contoh: Management Keuangan"
                  />
                </div>
              </div>

              {/* Divisi Dropdown */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Divisi</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4] justify-start items-center gap-2 inline-flex">
                  <Select
                    value={division}
                    onChange={setDivision}
                    options={divisions}
                    placeholder="Pilih Divisi"
                    isClearable
                    menuPortalTarget={document.body}
                    styles={{
                      control: (base) => ({ ...base, zIndex: 9999 }), // Z-index pada control input
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Z-index pada dropdown menu
                    }}
                    isSearchable
                  />
                </div>
              </div>
            </div>

            <div className="self-stretch px-4 py-3 border-t border-[#cdd0d4] justify-between items-center inline-flex">
              <button onClick={closeModal} className="px-5 bg-[#f5f6f6] rounded justify-center items-center gap-3 flex p-2">
                <div className="text-[#393b40] text-[15px] font-medium capitalize">Batal</div>
              </button>
              <button
                onClick={handleUpload}
                className="px-5 bg-[#0693ff] rounded justify-center items-center gap-3 flex p-2"
                disabled={isUploading}
              >
               <div className="text-[white] text-[15px] font-medium capitalize"> {isUploading ? 'Uploading...' : 'Upload'}</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UploadModal;
