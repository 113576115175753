import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import { useBranch } from '../../contex/BranchContex.js';

const UserModal = ({ isModalOpen, closeModal, handleUserSuccess, userData }) => {
  const [fullname, setFullname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;
  const branch = useBranch();

  // Fetch roles for dropdown
  const fetchRoles = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${apiUrl}/role`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchData.id,
        },
      });

      if (response.ok) {
        const result = await response.json();
        const options = result.data.map(role => ({
          value: role.id,
          label: role.name,
        }));
        setRoles(options);
      } else {
        console.error('Error fetching roles:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchRoles();
      if (userData) {
        setFullname(userData.fullname);
        setUsername(userData.username);
        setRole({ value: userData.role_id, label: userData.role_name });
        // Simpan password di state sementara tanpa mengubahnya
        setPassword(''); // Tidak mengubah password saat mengedit
      } else {
        setFullname('');
        setUsername('');
        setRole(null);
        setPassword(''); // Reset password jika modal baru dibuka
      }
    }
  }, [isModalOpen, userData]);

  const handleSave = async () => {
    if (!fullname || !username || !role) {
      alert('Nama, Username, dan Role harus diisi');
      return;
    }
  
    const token = localStorage.getItem('accessToken');
    const userId = userData ? userData.id : '';
    const method = userId ? 'PUT' : 'POST';
    const url = userId ? `${apiUrl}/user/${userId}` : `${apiUrl}/user`;
    const payload = {
      fullname,
      username,
      password: password || userData.password, // Gunakan password lama jika tidak ada input
      role_id: role.value !== null ? role.value : userData.role_id,
      is_admin: false,  // Menambahkan properti is_admin
    };
  
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method,
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-branch': branch.branchData.id,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        alert('Pengguna berhasil disimpan!');
        handleUserSuccess();
        setPassword(''); // Reset password setelah penyimpanan berhasil
        closeModal();
      } else {
        const result = await response.json();
        alert('Gagal menyimpan pengguna: ' + result.message);
      }
    } catch (error) {
      console.error('Error saving user:', error);
      alert('Terjadi kesalahan saat menyimpan pengguna');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-[560px] bg-white rounded-xl flex-col inline-flex">
            <div className="self-stretch px-4 py-3 border-b border-[#cdd0d4] flex items-center gap-3">
              <div className="text-[#393b40] text-[19px] font-semibold capitalize">
                {userData ? 'Edit Pengguna' : 'Tambah Pengguna'}
              </div>
            </div>

            <div className="self-stretch overflow-y-auto px-4 py-3 flex-col flex" style={{ maxHeight: 'calc(80vh - 72px)' }}>
              {/* Nama Pengguna */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Nama Lengkap</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4]">
                  <input
                    type="text"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Contoh: John Doe"
                  />
                </div>
              </div>

              {/* Username */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Username</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4]">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Contoh: johndoe"
                  />
                </div>
              </div>

              {/* Password */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Password</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4]">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Masukkan password jika ingin mengubah"
                  />
                </div>
              </div>

              {/* Role Dropdown */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex ">
                <div className="text-[#393b40] text-xs font-medium capitalize">Role</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4] ">
                  <Select
                    value={role}
                    onChange={setRole}
                    options={roles}
                    className='mt-1'
                    placeholder="Pilih Role"
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
            </div>

            <div className="self-stretch px-4 py-3 border-t border-[#cdd0d4] justify-between items-center inline-flex">
              <button onClick={closeModal} className="px-5 bg-[#f5f6f6] rounded p-2">
                <div className="text-[#393b40] text-[15px] font-medium capitalize">Batal</div>
              </button>
              <button
                onClick={handleSave}
                className="px-5 bg-[#0693ff] rounded p-2"
                disabled={isLoading}
              >
                <div className="text-[white] text-[15px] font-medium capitalize">{isLoading ? 'Menyimpan...' : 'Simpan'}</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserModal;
