import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-5xl font-bold text-red-500">404 - Branch Not Found</h1>
      <p className="text-lg mt-4 text-gray-600">Sorry, the branch you are looking for does not exist or was not found.</p>
      <Link to="/" className="mt-6 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
        Go Back to Homepage
      </Link>
    </div>
  );
};

export default NotFoundPage;
