import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import Navigation from '../molecules/Navigation.js';

// Fungsi untuk memformat pesan
const formatMessage = (message) => {
  const parts = message.split(/(\*\*[^*]+\*\*|_[^_]+_)/g); // Pisahkan berdasarkan penanda tebal dan miring
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <b key={index}>{part.slice(2, -2)}</b>; // Teks tebal
    } else if (part.startsWith('_') && part.endsWith('_')) {
      return <i key={index}>{part.slice(1, -1)}</i>; // Teks miring
    }
    return part; // Kembalikan teks biasa
  });
};

const TypingAnimation = ({ message, speed }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < message.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + message[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, speed);

      return () => clearTimeout(timeoutId);
    }
  }, [index, message, speed]);

  return <span>{formatMessage(displayedText)}</span>; // Terapkan pemformatan saat mengetik
};

const apiKey = process.env.REACT_APP_API_KEY;

const ResetPage = () => {
  const [messages, setMessages] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [input, setInput] = useState('');
  const token = localStorage.getItem('accessToken');
  const messagesEndRef = useRef(null);
  const socketRef = useRef();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    socketRef.current = io("https://api.tanyaaja.id/chat", {
      extraHeaders: {
        "Authorization": `Bearer ${token}`,
        "x-api-key": apiKey,
      },
    });

    let fullResponse = '';

    socketRef.current.on('receive', (data) => {
      setIsWaiting(false);
      fullResponse += data.data;

      // Periksa apakah pesan sudah lengkap
      if (data.data.endsWith('.')) {
        setTimeout(() => {
          setMessages((prev) => [
            ...prev,
            { sender: 'server', message: fullResponse }
          ]);
          fullResponse = ''; // Reset setelah mengirim
        }, 1000); // Simulasi delay untuk efek mengetik
      }
    });

    return () => {
      socketRef.current.off('receive');
      socketRef.current.disconnect();
    };
  }, [token, apiKey]);

  const handleSend = () => {
    if (input.trim() === '') return;

    setMessages((prev) => [...prev, { sender: 'user', message: input }]);
    setIsWaiting(true);

    const data = { prompt: input };
    socketRef.current.emit('message', data);
    setInput('');
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-start items-center">
      <Navigation />
      <div className="w-full max-w-[696px] flex-grow py-5 flex flex-col justify-between items-start px-4 sm:px-0">
        <div className="flex flex-col justify-center items-center gap-10">
          <div className="flex flex-col justify-center items-start">
            <div className="text-[#cdd0d4] text-[32px] sm:text-[46px] font-semibold font-['Poppins']">Mulai Sekarang,</div>
            <div className="text-[#0693ff] text-[32px] sm:text-[46px] font-semibold font-['Poppins']">Tanya Aja!</div>
          </div>
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="text-[#393b40] text-[14px] sm:text-[15px] font-normal font-['Poppins'] text-center">
              Silakan tanyakan apapun yang berhubungan dengan Divisi HR, mulai dari kebijakan hingga pengembangan karyawan.
            </div>
            <div className="flex flex-col gap-3 w-full max-h-96 overflow-y-auto bg-[#f5f6f6] p-4 rounded-lg">
              {messages.map((msg, index) => (
                msg.message.length > 0 ? (
                  <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                    <div className={`p-2 rounded-lg ${msg.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>
                      {msg.sender === 'server' ? (
                        <TypingAnimation message={msg.message} speed={10} />
                      ) : (
                        formatMessage(msg.message) // Format untuk pesan pengguna
                      )}
                    </div>
                  </div>
                ) : null
              ))}
              <div ref={messagesEndRef} />
              {isWaiting && (
                <div className="flex justify-center">
                  <div className="p-3 rounded-lg bg-green-300 text-green-900 italic">Waiting Response...</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-center gap-2">
          <div className="w-full h-14 px-4 sm:px-6 bg-white rounded-full border border-[#cdd0d4] flex justify-between items-center">
            <input
              type="text"
              value={input}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setIsWaiting(true);
                  e.preventDefault();
                  handleSend();
                }
              }}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ketik Pertanyaan atau perintah dengan AI..."
              className="flex-grow outline-none text-[#6a717c] text-[14px] sm:text-[15px] font-normal font-['Poppins']"
            />
            <button onClick={handleSend} className="text-[#0693ff] font-semibold">Kirim</button>
          </div>
          <div className="text-[#808790] text-xs font-normal font-['Poppins'] text-center">
            Pertanyaan hanya bisa sesuai topik divisi anda, pertanyaan diluar topik tidak akan dijawab.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPage;
