import React, { createContext, useState, useEffect } from 'react';
import fetchBranchData from '../api/branchApi';

export const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
  const [branchCode, setBranchCode] = useState(null);  // Simpan kode cabang
  const [branchData, setBranchData] = useState(null);  // Simpan data cabang
  const [loading, setLoading] = useState(true);        // Indikator loading

  useEffect(() => {
    const getBranchData = async () => {
      if (branchCode) {  // Lakukan fetch jika ada branchCode
        try {
          setLoading(true);  // Set loading true saat memulai fetch
          const response = await fetchBranchData(branchCode);  // Fetch data cabang
          setBranchData(response.data);  // Simpan data cabang
          console.log('Branch Data:', response.data);  // Debugging log
        } catch (error) {
          console.error('Error fetching branch data:', error);
        } finally {
          setLoading(false);  // Set loading ke false setelah fetch selesai
        }
      }
    };

    getBranchData();  // Panggil fungsi fetch data
  }, [branchCode]);  // Jalankan useEffect saat branchCode berubah

  return (
    <BranchContext.Provider value={{ branchCode, branchData, setBranchCode, loading }}>
      {children}
    </BranchContext.Provider>
  );
};

// Hook untuk menggunakan context
export const useBranch = () => {
  const context = React.useContext(BranchContext);
  if (!context) {
    throw new Error('useBranch must be used within a BranchProvider');
  }
  return context;
};
