// import { useState } from "react";
import Header from "../atoms/Header";



const RegisterForm = ({ onSubmit }) => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onSubmit({ email, password });
  // };

  return (
<form className="w-full max-w-[600px] flex flex-col gap-5">
<div className="flex flex-col items-center space-y-2">
      <div className="text-center">
        <Header text="Daftar ke" color="black" />
        <Header text="TanyaAja" color="#0693ff" />
      </div>
      <div className="text-[#393b40] text-[15px] font-normal font-['Poppins']">
      Buat akun dan akses percakapan pintar.
      </div>
    </div>
  
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">
          Nama Lengkap
        </label>
        <input
          type="text"
          placeholder="Masukan nama lengkap anda"
          className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
        />
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">
          Divisi
        </label>
        <div className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] flex justify-between items-center">
          <span className="text-[#393b40] text-[15px] font-normal font-['Poppins']">
            Pilih Divisi
          </span>
          <div className="w-4 h-4 flex items-center justify-center">
            {/* Tambahkan ikon dropdown jika diperlukan */}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">
          Email
        </label>
        <input
          type="email"
          placeholder="Masukan email anda"
          className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
        />
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-end w-full">
          <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">
            Password
          </label>
          <span className="opacity-0 text-[#0693ff] text-[15px] font-medium font-['Poppins'] capitalize">
            Lupa Password
          </span>
        </div>
        <input
          type="password"
          placeholder="Masukan password"
          className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
        />
        <div className="flex flex-col gap-1 mt-1">
          <span className="text-[#808790] text-xs font-medium font-['Poppins']">Password harus:</span>
          <ul className="list-disc ml-5 text-[#808790] text-xs font-normal font-['Poppins']">
            <li>Minimal 8 karakter</li>
            <li>Huruf besar</li>
            <li>Nomor</li>
            <li>Simbol</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-end w-full">
          <label className="text-[#393b40] text-xs font-medium font-['Poppins'] capitalize">
            Ulangi Password
          </label>
          <span className="opacity-0 text-[#0693ff] text-[15px] font-medium font-['Poppins'] capitalize">
            Lupa Password
          </span>
        </div>
        <input
          type="password"
          placeholder="Masukan password"
          className="w-full h-12 px-4 bg-white rounded border border-[#cdd0d4] text-[#6a717c] text-[15px] font-normal font-['Poppins']"
        />
        <div className="text-[#00b308] text-xs font-normal font-['Poppins']">Password cocok</div>
      </div>
    </div>

    <button className="w-full h-12 bg-[#0693ff] rounded flex justify-center items-center">
      <span className="text-[#edfaff] text-[15px] font-medium font-['Poppins'] capitalize">Daftar</span>
    </button>
  </form>
  
  );
};

export default RegisterForm;
