import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import { useBranch } from '../../contex/BranchContex.js';

const DivisionModal = ({ isModalOpen, closeModal, handleDivisionSuccess, divisionData }) => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;
  const branch = useBranch();

  useEffect(() => {
    if (isModalOpen && divisionData) {
      setCode(divisionData.code);
      setName(divisionData.name);
    } else {
      setCode('');
      setName('');
    }
  }, [isModalOpen, divisionData]);

  const handleSave = async () => {
    if (!code || !name) {
      alert('Code dan Nama harus diisi');
      return;
    }

    const token = localStorage.getItem('accessToken');
    const divisionId = divisionData ? divisionData.id : '';
    const method = divisionId ? 'PUT' : 'POST';
    const url = divisionId ? `${apiUrl}/role/${divisionId}` : `${apiUrl}/role`;

    const payload = {
      code,
      name,
    };
    console.log('modal divisi',branch)

    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method,
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'x-branch': branch.branchData.id,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Divisi berhasil disimpan!');
        handleDivisionSuccess();
        closeModal();
      } else {
        const result = await response.json();
        alert('Gagal menyimpan divisi: ' + result.message);
      }
    } catch (error) {
      console.error('Error saving division:', error);
      alert('Terjadi kesalahan saat menyimpan divisi');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-[560px] bg-white rounded-xl flex-col inline-flex">
            <div className="self-stretch px-4 py-3 border-b border-[#cdd0d4] flex items-center gap-3">
              <div className="text-[#393b40] text-[19px] font-semibold capitalize">
                {divisionData ? 'Edit Divisi' : 'Tambah Divisi'}
              </div>
            </div>

            <div className="self-stretch overflow-y-auto px-4 py-3 flex-col flex" style={{ maxHeight: 'calc(80vh - 72px)' }}>
              {/* Code */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Kode</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4]">
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Contoh: A123"
                  />
                </div>
              </div>

              {/* Name */}
              <div className="h-[74px] flex-col justify-center items-start gap-2 flex">
                <div className="text-[#393b40] text-xs font-medium capitalize">Nama</div>
                <div className="self-stretch h-12 px-4 bg-white rounded border border-[#cdd0d4]">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full h-full px-2 text-[#6a717c] text-[15px] outline-none"
                    placeholder="Contoh: HR"
                  />
                </div>
              </div>
            </div>

            <div className="self-stretch px-4 py-3 border-t border-[#cdd0d4] justify-between items-center inline-flex">
              <button onClick={closeModal} className="px-5 bg-[#f5f6f6] rounded p-2">
                <div className="text-[#393b40] text-[15px] font-medium capitalize">Batal</div>
              </button>
              <button
                onClick={handleSave}
                className="px-5 bg-[#0693ff] rounded p-2"
                disabled={isLoading}
              >
                 <div className="text-[white] text-[15px] font-medium capitalize">{isLoading ? 'Menyimpan...' : 'Simpan'}</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DivisionModal;
