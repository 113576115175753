import RegisterForm from "../molecules/RegisterForm";
import Logo from "./Logo.png";
// import Background from "/Bg.png";

const RegisterPage = () => {
  const handleLogin = (data) => {
    console.log("Login data:", data);
    // Implementasikan login dengan socket.io atau API
  };

  return (
    <div className="w-full h-screen bg-cover bg-center flex relative">
      <img
         src="https://i.imgur.com/2Exr44m.png"
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
      />
      <div className="flex w-full h-full">
        {/* Left Section */}
        <div className="flex-1 flex justify-center items-center bg-black bg-opacity-5">
          <img
            src={Logo}
            width={240}
            alt="Logo"
            className="h-32 object-contain"
          />
        </div>

        {/* Right Section with scrollable form */}
        <div className="flex-1 flex justify-center items-center bg-white bg-opacity-80 overflow-y-auto">
          <div className="w-full max-w-md h-full px-4 py-6">
            <div className="max-h-full ">
              <RegisterForm onSubmit={handleLogin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
