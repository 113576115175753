import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useBranch } from '../../contex/BranchContex.js';
import ConfirmModal from '../molecules/ConfirmModal.js';
import UserModal from '../molecules/UserModal'; // Import the UserModal component
import Navigation from '../molecules/Navigation.js';

const UserManagementPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [filterRole, setFilterRole] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const branch = useBranch();

  const openConfirmModal = (id) => {
    setItemToDelete(id);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setItemToDelete(null);
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const confirmDelete = () => {
    handleDelete(itemToDelete);
    closeConfirmModal();
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${apiUrl}/user/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchCode.id,
        },
      });

      if (response.ok) {
        fetchData();
      } else {
        console.error('Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const openAddUserModal = () => {
    setIsModalOpen(true);
    setSelectedUser(null); // Clear selected user for new user
  };

  // Open modal for editing a user
  const openEditUserModal = (user) => {
    setIsModalOpen(true);
    setSelectedUser(user); // Set the selected user for editing
  };

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/user?search=${search}&order=${order}&role=${filterRole}&page=${page}`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchData.code,
        },
      });

      const res = await response.json();
      setData(res.data.item);
      setTotalPages(Math.ceil(res.data.total / pageSize));
      setTotalItems(res.data.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page,search, order, filterRole]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSort = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterChange = (e) => {
    setFilterRole(e.target.value);
  };

  const handleResetFilters = () => {
    setSearch('');
    setOrder('asc');
    setFilterRole('');
  };

   // Handle successful user addition or update
   const handleUserSuccess = () => {
    fetchData(); // Refresh the user list after adding or editing a user
    setIsModalOpen(false); // Close the modal
    setSelectedUser(null); // Reset selected user
    setDropdownOpen(null)
  };


  return (
    <div className="w-full h-screen bg-white flex flex-col justify-start items-center">
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={confirmDelete}
        message="Apakah Anda yakin ingin menghapus pengguna ini?"
      />
      <UserModal 
        isModalOpen={isModalOpen} 
        closeModal={() => setIsModalOpen(false)} 
        handleUserSuccess={handleUserSuccess} 
        userData={selectedUser} 
      />
      <Navigation />
      <div className="flex-grow flex flex-col gap-6 w-full max-w-7xl px-[20%]">
        <div className="flex flex-col items-start w-full gap-2 sm:gap-4">
          <h1 className="text-2xl sm:text-4xl font-semibold text-gray-800">Manajemen Pengguna</h1>
          <p className="text-sm sm:text-base text-gray-600">Kelola pengguna dan hak akses mereka di sini.</p>
        </div>

        {/* Filter, Search and Add Section */}
        <div className="flex flex-col sm:flex-row justify-between w-full">
          <div className="flex items-center w-full sm:w-80 h-12 bg-white border border-gray-300 rounded-full px-4">
            <input
              value={search}
              onChange={handleSearch}
              className="w-full bg-transparent text-gray-700 placeholder-gray-500 text-sm focus:outline-none"
              placeholder="Cari pengguna"
            />
          </div>
          <button onClick={openAddUserModal} className="px-4 py-2 bg-blue-500 text-white rounded-full text-sm flex items-center mt-2 sm:mt-0">
            Tambah Pengguna
          </button>
        </div>

        {/* Filter and Sort Section */}
        <div className="w-full h-10 justify-between items-center inline-flex mt-4">
          

          <select value={filterRole} onChange={handleFilterChange} className="bg-white border border-gray-300 rounded-full px-3 h-10">
            <option value="">Semua Role</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
            {/* Tambahkan role lainnya sesuai kebutuhan */}
          </select>

          <div className="justify-center items-center gap-1 flex">
            <div className="text-[#6a717c] text-[15px] font-normal font-['Poppins']">Urutkan berdasarkan:</div>
            <div onClick={handleSort} className="text-[#0693ff] text-[15px] font-medium font-['Poppins'] cursor-pointer">
              {order === 'asc' ? 'Terlama' : 'Terbaru'}
            </div>
          </div>
        </div>

        <div className="hidden sm:flex w-full bg-gray-100 rounded-lg items-center p-3 text-gray-800">
          <div className="w-12 text-center font-medium">No</div>
          <div className="flex-1 font-medium">Nama Lengkap</div>
          <div className="flex-1 font-medium">Username</div>
          <div className="flex-1 font-medium">Role</div>
          <div className="w-24 text-center font-medium">Aksi</div>
        </div>
        <div className="w-full bg-white rounded-lg shadow">
  {loading ? (
    <div className="p-4 text-center">Loading...</div>
  ) : (
    Array.isArray(data) && data.length > 0 ? ( // Check if data is an array and has elements
      data.map((user, index) => (
        <div key={user.id} className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-100 p-3">
          <div className="w-12 text-center text-gray-700">{index + 1}</div>
          <div className="flex-1 text-gray-700">{user.fullname}</div>
          <div className="flex-1 text-gray-700">{user.username}</div>
          <div className="flex-1 text-gray-700">{user.role_name}</div>
          <div className="relative w-24 flex justify-center">
          <button onClick={() => toggleDropdown(user.id)} className="text-gray-600 hover:text-gray-800 px-3 py-1 rounded focus:outline-none">
  ⋮
</button>
{dropdownOpen === user.id && (  // Mengubah user.uuid ke user.id
  <div className="absolute top-full right-0 mt-2 w-36 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
    <button
      onClick={() => openEditUserModal(user)}  // Mengganti ke tombol Edit
      className="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
    >
      Edit
    </button>
    <button
      onClick={() => openConfirmModal(user.id)}
      className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100"
    >
      Delete
    </button>
  </div>
)}

                </div>
        </div>
      ))
    ) : (
      <div className="p-4 text-center text-gray-500">No users found.</div> // Message when no users are present
    )
  )}
</div>
<div className="w-full flex justify-between items-center py-4 text-sm">
          <div className="text-gray-600">{`Menampilkan ${data.length} dari ${totalItems} data`}</div>
          <div className="flex gap-2">
            {[...Array(totalPages)].map((_, idx) => (
              <button
                key={idx}
                onClick={() => handlePageChange(idx + 1)}
                className={`px-3 py-1 rounded-full ${page === idx + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'}`}
              >
                {idx + 1}
              </button>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default UserManagementPage;
