// import { Link } from 'react-router-dom';

const ResetPage = () => {
  const SectionList = ({ title, children }) => (
    <div className="w-full">
      <div className="text-[#888888] text-xs font-medium">{title}</div>
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
  
  const Message = ({ content, highlighted }) => (
    <div
      className={`w-full h-10 px-3 rounded-lg flex items-center ${
        highlighted ? 'bg-[#eff0f6] text-[#ff2271]' : 'text-[#474f58]'
      }`}
    >
      <span className="text-[15px] font-normal">{content}</span>
    </div>
  );
  
  const MessageBox = ({ content }) => (
    <div className="w-[480px] p-3 bg-[#d8dee5] rounded-lg">
      <span className="text-[#474f58] text-[15px]">{content}</span>
    </div>
  );
  
  const Header = ({ title }) => (
    <div className="flex items-center gap-2">
      <div className="w-6 h-6 bg-white rounded-full shadow flex justify-center items-center"></div>
      <h1 className="text-[#474f58] text-[19px] font-medium">{title}</h1>
    </div>
  );

  return (
<div className="w-full min-h-screen p-6 bg-[#eceef2] flex flex-col md:flex-row justify-start items-start gap-6">
  <div className="w-[280px] p-3 bg-white rounded-xl shadow flex flex-col justify-start items-start gap-3">
    <div className="flex flex-col gap-5">
      <div className="w-full flex justify-between items-center">
        <div className="w-10 h-10 bg-gray-200 rounded-lg flex justify-center items-center">
          <div className="w-6 h-6">
            <div className="w-5 h-[18px]"></div>
          </div>
        </div>
        <div className="w-10 h-10 bg-gray-200 rounded-lg flex justify-center items-center">
          <div className="w-6 h-6">
            <div className="w-5 h-5"></div>
          </div>
        </div>
      </div>

      {/* Kemarin */}
      <SectionList title="Kemarin">
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
        <Message content="Lorem ipsum dolor sit amet consectetur..." highlighted />
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
      </SectionList>

      {/* 7 Hari Lalu */}
      <SectionList title="7 hari yang lalu">
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
      </SectionList>

      {/* September */}
      <SectionList title="September">
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
        <Message content="Lorem ipsum dolor sit amet consectetur..." />
      </SectionList>
    </div>

    <div className="w-full border border-[#dadef3] my-3"></div>

    <div className="w-full flex justify-between items-center">
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 bg-[#879dd7] rounded-full flex justify-center items-center">
          <span className="text-white text-[15px] font-medium">FD</span>
        </div>
        <div>
          <div className="text-[#121212] text-[15px] font-medium">Ferodika</div>
          <div className="text-[#888888] text-xs">ferodika@gmail.com</div>
        </div>
      </div>
    </div>
  </div>

  {/* Full Width Content Section */}
  <div className="flex flex-col flex-grow justify-end items-start gap-4 w-full">
    <Header title="Cerebra" />
    <div className="w-full max-w-[1280px] flex flex-col gap-6">
      <div className="flex justify-end">
        <MessageBox content="Lorem ipsum dolor sit amet consectetur..." />
      </div>
      <div className="flex items-start gap-2">
        <MessageBox content="Lorem ipsum dolor sit amet consectetur..." />
      </div>
      <div className="flex justify-end">
        <MessageBox content="Lorem ipsum dolor sit amet consectetur..." />
      </div>

      {/* Bagian yang Hilang Ditambahkan Kembali */}
      <div className="w-full h-12 px-3 bg-[#fafafb] rounded-lg shadow justify-between items-center inline-flex" style={{marginTop:270}}>
        <div className="text-[#b1b6c6] text-[15px] font-normal font-['Poppins']">
          Ask anything you want...
        </div>
        <div className="w-6 h-6 p-0.5 justify-center items-center flex">
          <svg className="w-full h-full" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 3a1 1 0 011 1v4h4a1 1 0 110 2h-5a1 1 0 01-1-1V4a1 1 0 011-1z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

  );

};

export default ResetPage;
