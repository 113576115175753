const fetchBranchData = async (code) => {
    const response = await fetch(`https://api.tanyaaja.id/api/v1/branch/${code}`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Access-Control-Allow-Origin': '*'
        }
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

export default fetchBranchData; // Pastikan ini adalah ekspor default
