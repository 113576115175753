import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useBranch } from '../../contex/BranchContex.js';
import UploadModal from '../molecules/UploadModal.js';
import ConfirmModal from '../molecules/ConfirmModal.js';
import Navigation from '../molecules/Navigation.js';

const SettingPage = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const branch = useBranch();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openConfirmModal = (id) => {
    setItemToDelete(id);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setItemToDelete(null);
  };

  const confirmDelete = () => {
    handleDelete(itemToDelete);
    closeConfirmModal();
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${apiUrl}/dataset/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
          'x-branch': branch.branchCode.code,
        },
      });

      if (response.ok) {
        fetchData();
      } else {
        console.error('Failed to delete dataset');
      }
    } catch (error) {
      console.error('Error deleting dataset:', error);
    }
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataset?page=${page}&page_size=${pageSize}&order=${order}&search=${search}`, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Authorization': `Bearer ${token}`,
        },
      });

      const res = await response.json();
      setData(res.data.item);
      setTotalPages(Math.ceil(res.data.total / pageSize));
      setTotalItems(res.data.total);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, search, order]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleUploadSuccess = () => {
    fetchData();
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-start items-center ">
      <UploadModal isModalOpen={isModalOpen} closeModal={closeModal} handleUploadSuccess={handleUploadSuccess} />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={confirmDelete}
        message="Apakah Anda yakin ingin menghapus dataset ini?"
      />
      <Navigation />
      <div className="flex-grow flex flex-col gap-6 w-full max-w-7xl lg:px-[20%] p-10">
        <div className="flex flex-col items-start w-full gap-2 sm:gap-4">
          <h1 className="text-2xl sm:text-4xl font-semibold text-gray-800">Datasheet</h1>
          <p className="text-sm sm:text-base text-gray-600">Tambahkan data spesifik untuk memperkaya kemampuan AI dalam menjawab pertanyaan topik tertentu.</p>
        </div>
        <div className="flex flex-col sm:flex-row justify-between w-full gap-4 sm:gap-0">
          <div className="flex items-center w-full sm:w-80 h-12 bg-white border border-gray-300 rounded-full px-4">
            <input value={search} onChange={handleSearch} className="w-full bg-transparent text-gray-700 placeholder-gray-500 text-sm focus:outline-none" placeholder="Cari datasheet" />
          </div>
          <button onClick={openModal} className="px-4 py-2 bg-blue-500 text-white rounded-full text-sm flex items-center">
            Tambah Datasheet
          </button>
        </div>
        <div className="flex flex-col sm:flex-row justify-between w-full gap-4 sm:gap-0">
          <button className="px-4 py-2 bg-gray-100 rounded-full text-sm">
            Filter
          </button>
          <div className="flex items-center gap-2 text-sm">
            <span className="text-gray-600">Urutkan berdasarkan:</span>
            <button onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')} className="text-blue-500 font-medium">
              {order === 'asc' ? 'Terlama' : 'Terbaru'}
            </button>
          </div>
        </div>
        <div className="hidden sm:flex w-full bg-gray-100 rounded-lg items-center p-3 text-gray-800">
          <div className="w-12 text-center font-medium">No</div>
          <div className="flex-1 font-medium">Nama Datasheet</div>
          <div className="flex-1 font-medium">Tipe</div>
          <div className="flex-1 font-medium">Divisi</div>
          <div className="flex-1 font-medium">Tanggal Update</div>
          <div className="w-24 text-center font-medium">Aksi</div>
        </div>
        <div className="w-full bg-white rounded-lg shadow">
          {loading ? (
            <div className="p-4 text-center">Loading...</div>
          ) : (
            data.map((item, index) => (
              <div key={item.uuid} className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-100 p-3">
                <div className="w-12 text-center text-gray-700">{(page - 1) * pageSize + index + 1}</div>
                <div className="flex-1 text-gray-700">{item.name}</div>
                <div className="flex-1 text-gray-700">{item.type}</div>
                <div className="flex-1 text-gray-700">{item.division || '-'}</div>
                <div className="flex-1 text-gray-700">{item.updated_at.split('T')[0]}</div>
                <div className="relative w-24 flex justify-center">
                  <button onClick={() => toggleDropdown(item.uuid)} className="text-gray-600 hover:text-gray-800 px-3 py-1 rounded focus:outline-none">
                    ⋮
                  </button>
                  {dropdownOpen === item.uuid && (
                    <div className="absolute top-full right-0 mt-2 w-36 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
                      <button
                        onClick={() => openConfirmModal(item.uuid)}
                        className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="w-full flex justify-between items-center py-4 text-sm">
          <div className="text-gray-600">{`Menampilkan ${data.length} dari ${totalItems} data`}</div>
          <div className="flex gap-2">
            {[...Array(totalPages)].map((_, idx) => (
              <button
                key={idx}
                onClick={() => handlePageChange(idx + 1)}
                className={`px-3 py-1 rounded-full ${page === idx + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-600'}`}
              >
                {idx + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
